<template>
  <!--服务配置 模块-->
  <div>
    <el-card shadow="never">
      <div class="where">
        <!--<el-form-->
        <!--    :model="where"-->
        <!--    label-width="90px"-->
        <!--    class="ele-form-search"-->
        <!--    @submit.native.prevent>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :lg="21" :md="12">-->
        <!--      <el-form-item label="服务商:">-->
        <!--        <el-select-->
        <!--            style="width: 300px !important;"-->
        <!--            clearable-->
        <!--            @change="selectwhere"-->
        <!--            v-model="where.id"-->
        <!--            placeholder="请选择服务商"-->
        <!--            class="ele-fluid">-->
        <!--          <el-option v-for="(item) in service" :label="item.company_name" :value="item.id"/>-->
        <!--        </el-select>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-form>-->
      </div>

      <!--时效配置-->
      <div class="ageing">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              时效配置
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="Showageing()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-bianji" />
                <span style="vertical-align: middle">编辑</span>
              </el-button>
            </div>
          </div>
        </div>

        <div class="ageing_b">
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">平台接单提醒：</span>
              <span class="ageing_b_text_r">{{current.platform_join_normal}}秒，考核：{{current.platform_join_assess}}</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">客服响应提醒：</span>
              <span class="ageing_b_text_r">{{current.service_response_normal}}秒，考核：{{current.service_response_assess}}</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">平台派单提醒：</span>
              <span class="ageing_b_text_r">{{current.platform_dispatch_normal}}秒，考核：{{current.platform_dispatch_assess}}</span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机接单提醒：</span>
              <span class="ageing_b_text_r">{{current.technician_join_normal}}秒，考核：{{current.technician_join_assess}}</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机接单至出发提醒：</span>
              <span class="ageing_b_text_r">{{current.technician_depart_normal}}秒，考核：{{current.technician_depart_assess}}</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机出发至到达提醒：</span>
              <span class="ageing_b_text_r">{{current.technician_arrive_normal}}秒，考核：{{current.technician_arrive_assess}}</span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">司机到达至完成提醒：</span>
              <span class="ageing_b_text_r">{{current.technician_accomplish_normal}}秒，考核：{{current.technician_accomplish_assess}}</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">订单完成至回访提醒：</span>
              <span class="ageing_b_text_r">{{current.technician_return_normal}}秒，考核：{{current.technician_return_assess}}</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">下单至司机接单提醒：</span>
              <span class="ageing_b_text_r">{{current.place_technician_join_normal}}秒，考核：{{current.place_technician_join_assess}}</span>
            </el-col>
          </el-row>
          <el-row style="margin: 15px;">
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">下单至司机出发提醒：</span>
              <span class="ageing_b_text_r">{{current.place_technician_depart_normal}}秒，考核：{{current.place_technician_depart_assess}}</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">下单至司机到达提醒：</span>
              <span class="ageing_b_text_r">{{current.place_technician_arrive_normal}}秒，考核：{{current.place_technician_arrive_assess}}</span>
            </el-col>
            <el-col :span="8" style="text-align: center">
              <span class="ageing_b_text_l">下单至完成提醒：</span>
              <span class="ageing_b_text_r">{{current.place_technician_accomplish_normal}}秒，考核：{{current.place_technician_accomplish_normal}}</span>
            </el-col>
          </el-row>
        </div>
      </div>

      <!--水印配置-->
      <div class="ageing" style="margin-top: 20px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              水印配置
            </div>
          </div>
          <div>
            <div class="custom" style="margin-left: 20px">
              <el-button class="custom-button" @click="WatermarkConfiguration()">
                <!--el-icon-devops 是自己命名的-->
                <i class="el-icon-bianji" />
                <span style="vertical-align: middle">编辑</span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="ageing_b">
          <el-row style="margin: 15px;">
            <!--<el-col :span="12" style="text-align: center">-->
            <!--  <div class="parallel">-->
            <!--    <div class="left">-->
            <!--      内容：-->
            <!--    </div>-->
            <!--    <div class="right">-->
            <!--      <el-form ref="form" :model="form" label-width="80px">-->
            <!--        <el-form-item label="救援师傅">-->
            <!--          <el-input v-model="form.name" placeholder="请输入救援师傅姓名" clearable :disabled="true"></el-input>-->
            <!--        </el-form-item>-->
            <!--        <el-form-item label="经纬度">-->
            <!--          <el-input v-model="form.name" placeholder="请输入经纬度" clearable :disabled="true"></el-input>-->
            <!--        </el-form-item>-->
            <!--        <el-form-item label="上传时间">-->
            <!--          <el-input v-model="form.name" placeholder="请输入上传时间" clearable :disabled="true"></el-input>-->
            <!--        </el-form-item>-->
            <!--        <el-form-item label="地址">-->
            <!--          <el-input v-model="form.name" placeholder="请输入地址" clearable :disabled="true"></el-input>-->
            <!--        </el-form-item>-->
            <!--      </el-form>-->
            <!--    </div>-->
            <!--  </div>-->
            <!--</el-col>-->
            <!--<el-col :span="12" style="text-align: center">-->
            <!--  <div class="parallel">-->
            <!--    <div class="left">-->
            <!--      示例图：-->
            <!--    </div>-->
            <!--    <div class="right">-->
            <!--      <div class="right_img" :style="{ backgroundImage: `url(${img})` }" >-->
            <!--        <span style="color: #FF0707; font-size: 12px; text-align: left">2023/01/09  12:16  罗建军  上板照片广东省佛山市南海区招商悦府(建设中)lng:113.039883lat:2.896912</span>-->
            <!--      </div>-->
            <!--    </div>-->
            <!--  </div>-->
            <!--</el-col>-->
            <div class="right_img" :style="{ backgroundImage: `url(${img})` }" >
              <!--color: #FF0707; font-size: 12px; text-align: left-->
              <div v-for="(item,index) in list.variable" :key="index">
                <div>
                  <span style="color: #ff9b05;" v-for="(it,idx) in list.variable[index]" :key="idx" :style="{color: list.color,fontWeight:list.font_width}">
                    {{it}}
                  </span>
                </div>
              </div>
            </div>
          </el-row>
        </div>
      </div>



      <!--客户好评-->
      <div class="ageing" style="margin-top: 20px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              客户好评开关配置
            </div>
          </div>
        </div>
        <div class="ageing_b">
          <el-row style="margin: 15px;">
            <span>是否开启：</span>
            <el-switch v-model="value1" @change="changehaoping"></el-switch>
          </el-row>
        </div>
      </div>

      <!--照片模块配置-->
      <!--<div class="ageing" style="margin-top: 20px;">-->
      <!--  <div class="Datalist">-->
      <!--    <div class="Datalist_l">-->
      <!--      <div class="Datalist_icon"></div>-->
      <!--      <div class="Datalist_text">-->
      <!--        照片模板配置-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div>-->
      <!--      <div class="custom" style="margin-left: 20px">-->
      <!--        <el-button class="custom-button" @click="photoTemplate()">-->
      <!--          &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
      <!--          <i class="el-icon-bianji" />-->
      <!--          <span style="vertical-align: middle">编辑</span>-->
      <!--        </el-button>-->
      <!--      </div>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--  <div class="ageing_b">-->
      <!--    <el-row style="margin: 15px;">-->
      <!--      <el-col :span="8" style="text-align: center">-->
      <!--        <span class="ageing_b_text_l">拖车：</span>-->
      <!--        <span class="ageing_b_text_r">拖车专用模板</span>-->
      <!--      </el-col>-->
      <!--      <el-col :span="8" style="text-align: center">-->
      <!--        <span class="ageing_b_text_l">搭电：</span>-->
      <!--        <span class="ageing_b_text_r">搭电专用模板</span>-->
      <!--      </el-col>-->
      <!--      <el-col :span="8" style="text-align: center">-->
      <!--        <span class="ageing_b_text_l">换胎：</span>-->
      <!--        <span class="ageing_b_text_r">中华换胎专用</span>-->
      <!--      </el-col>-->
      <!--    </el-row>-->
      <!--    <el-row style="margin: 15px;">-->
      <!--      <el-col :span="8" style="text-align: center">-->
      <!--        <span class="ageing_b_text_l">困境：</span>-->
      <!--        <span class="ageing_b_text_r">中华困境专用</span>-->
      <!--      </el-col>-->
      <!--      <el-col :span="8" style="text-align: center">-->
      <!--        <span class="ageing_b_text_l">充气：</span>-->
      <!--        <span class="ageing_b_text_r">默认模板</span>-->
      <!--      </el-col>-->
      <!--      <el-col :span="8" style="text-align: center">-->
      <!--        <span class="ageing_b_text_l">送油：</span>-->
      <!--        <span class="ageing_b_text_r">默认模板</span>-->
      <!--      </el-col>-->
      <!--    </el-row>-->
      <!--  </div>-->
      <!--</div>-->

      <!--受理订单配置-->
      <!--<div class="ageing" style="margin-top: 20px;">-->
      <!--  <div class="Datalist">-->
      <!--    <div class="Datalist_l">-->
      <!--      <div class="Datalist_icon"></div>-->
      <!--      <div class="Datalist_text">-->
      <!--        受理订单配置-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div>-->
      <!--      <div class="custom" style="margin-left: 20px">-->
      <!--        <el-button class="custom-button" @click="DispatchModule()">-->
      <!--          &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
      <!--          <i class="el-icon-bianji" />-->
      <!--          <span style="vertical-align: middle">编辑</span>-->
      <!--        </el-button>-->
      <!--      </div>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--  <div class="ageing_b">-->
      <!--    <el-row style="margin: 15px;">-->
      <!--      <span>救援类型 </span>-->
      <!--      <span>派单模式 </span>-->
      <!--      <span>受理条件 (司机信息(车牌号 司机姓名 地址 经纬度) 车辆信息(车前脸包含车牌号照片) 状态(空闲 休息 可接单 忙碌))</span>-->
      <!--    &lt;!&ndash;  <el-col :span="8" style="text-align: center">&ndash;&gt;-->
      <!--    &lt;!&ndash;    <span class="ageing_b_text_l">接单模式：</span>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <span class="ageing_b_text_r">&ndash;&gt;-->
      <!--    &lt;!&ndash;      <span v-for="(item,index) in dict.type.orderreceiving_pattern" :key="index">&ndash;&gt;-->
      <!--    &lt;!&ndash;        <span v-if="dispatchModulecurrent.join_pattern == item.value">{{item.label}}</span>&ndash;&gt;-->
      <!--    &lt;!&ndash;      </span>&ndash;&gt;-->
      <!--    &lt;!&ndash;    </span>&ndash;&gt;-->
      <!--    &lt;!&ndash;  </el-col>&ndash;&gt;-->
      <!--    &lt;!&ndash;  <el-col :span="8" style="text-align: center">&ndash;&gt;-->
      <!--    &lt;!&ndash;    <span class="ageing_b_text_l">派单模式：</span>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <span class="ageing_b_text_r">&ndash;&gt;-->
      <!--    &lt;!&ndash;      <span v-for="(item,index) in dict.type.sendorders_pattern" :key="index">&ndash;&gt;-->
      <!--    &lt;!&ndash;        <span v-if="dispatchModulecurrent.send_pattern == item.value">{{item.label}}</span>&ndash;&gt;-->
      <!--    &lt;!&ndash;      </span>&ndash;&gt;-->
      <!--    &lt;!&ndash;    </span>&ndash;&gt;-->
      <!--    &lt;!&ndash;  </el-col>&ndash;&gt;-->
      <!--    &lt;!&ndash;  <el-col :span="8" style="text-align: center">&ndash;&gt;-->
      <!--    &lt;!&ndash;    <span class="ageing_b_text_l">拖车免拖里程：</span>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <span class="ageing_b_text_r">{{dispatchModulecurrent.free_mileage}}</span>&ndash;&gt;-->
      <!--    &lt;!&ndash;  </el-col>&ndash;&gt;-->
      <!--    &lt;!&ndash;</el-row>&ndash;&gt;-->
      <!--    &lt;!&ndash;<el-row style="margin: 15px;">&ndash;&gt;-->
      <!--    &lt;!&ndash;  <el-col :span="3" style="text-align: center">&ndash;&gt;-->
      <!--    &lt;!&ndash;    <span style="font-size: 16px;font-weight: 400;text-align: right;color: #868792;">派单注意事项：</span>&ndash;&gt;-->
      <!--    &lt;!&ndash;  </el-col>&ndash;&gt;-->
      <!--    &lt;!&ndash;  <el-col :span="21">&ndash;&gt;-->
      <!--    &lt;!&ndash;    <span style="font-size: 16px;font-weight: 500;text-align: left;color: #050505;">&ndash;&gt;-->
      <!--    &lt;!&ndash;      {{dispatchModulecurrent.attention}}&ndash;&gt;-->
      <!--    &lt;!&ndash;    </span>&ndash;&gt;-->
      <!--    &lt;!&ndash;  </el-col>&ndash;&gt;-->
      <!--    </el-row>-->
      <!--    <el-row style="margin: 15px;">-->
      <!--      &lt;!&ndash;<el-col :span="3" style="text-align: center">&ndash;&gt;-->
      <!--      &lt;!&ndash;  <span style="font-size: 16px;font-weight: 400;text-align: right;color: #868792;">接单服务说明：</span>&ndash;&gt;-->
      <!--      &lt;!&ndash;</el-col>&ndash;&gt;-->
      <!--      &lt;!&ndash;<el-col :span="21">&ndash;&gt;-->
      <!--      &lt;!&ndash;  <span style="font-size: 16px;font-weight: 500;text-align: left;color: #050505;">&ndash;&gt;-->
      <!--      &lt;!&ndash;    {{dispatchModulecurrent.explain}}&ndash;&gt;-->
      <!--      &lt;!&ndash;  </span>&ndash;&gt;-->
      <!--      &lt;!&ndash;</el-col>&ndash;&gt;-->
      <!--    </el-row>-->
      <!--  </div>-->
      <!--</div>-->
    </el-card>

    <!-- 时效编辑弹窗 -->
    <ageing-edit :id="id" :pid="pid" :data="current" :visible.sync="showEdit" @done="getConfig"/>
    <!-- 照片模板配置 -->
    <photoTemplate-edit v-if="showphotoTemplate === true" :data="photoTemplatecurrent" :visible.sync="showphotoTemplate"/>
    <!--派单模块配置-->
    <dispatchModule-edit v-if="showdispatchModule === true" :id="id" :dict="dict" :data="dispatchModulecurrent" :visible.sync="showdispatchModule" @done1="getConfig1"/>
    <!--水印配置-->
    <WatermarkConfigurationEdit v-if="showwatermarkConfiguration === true" :id="id" :data="watermarkConfigurationcurrent" :visible.sync="showwatermarkConfiguration" @done2="getConfig2"/>


  </div>
</template>

<script>
  // 引入时效设置弹窗
  import AgeingEdit from './components/ageing-edit.vue';
  //引入照片模板弹窗
  import PhotoTemplateEdit from './components/photoTemplate-edit.vue';
  //引入派单模块配置
  import DispatchModuleEdit from './components/dispatchModule-edit.vue';
  //引入水印配置
  import WatermarkConfigurationEdit from './components/watermarkConfiguration-edit.vue'
  import {
    getfacilitator,
    getget_dispatch_config,
    getservice_configuration,
    get_watermark,
    get_watermark_info, get_owners_favorable_comment_info, edit_owners_favorable_comment
  } from "@/api/custom";


  export default {
    components:{
      AgeingEdit,
      PhotoTemplateEdit,
      DispatchModuleEdit,
      WatermarkConfigurationEdit
    },
    props:{
      // 修改回显的数据
      data: Object,
      // 父级id
      id: Number,
      // 字典数据
      dict:Object,
    },
    data(){
      return{
        //条件
        where:{},
        // 水印配置表单数据
        form:{
          name:'',
        },

        // 示例图
        img: require('../../../../assets/images/home/shilitu.png'),

        // 时效数据
        // current: null,
        current:{
          // platform_join_remind:{
          //   normal:'',
          //   assess:''
          // },
          // service_response_remind:{
          //   normal:'',
          //   assess:''
          // },
          // technician_join_remind:{
          //   normal:'',
          //   assess:''
          // },
          // platform_dispatch_remind:{
          //   normal:'',
          //   assess:''
          // },
          // technician_depart_remind:{
          //   normal:'',
          //   assess:''
          // },
          // technician_arrive_remind:{
          //   normal:'',
          //   assess:''
          // },
          // technician_accomplish_remind:{
          //   normal:'',
          //   assess:'',
          // },
          // technician_return_remind:{
          //   normal:'',
          //   assess:''
          // },
          // place_technician_join_remind:{
          //   normal:'',
          //   assess:''
          // },
          // place_technician_depart_remind:{
          //   normal:'',
          //   assess:''
          // },
          // place_technician_arrive_remind:{
          //   normal:'',
          //   assess:''
          // },
          // place_technician_accomplish_remind:{
          //   normal:'',
          //   assess:''
          // },
        },
        // 是否显示时效弹窗
        showEdit: false,

        //照片模板配置
        photoTemplatecurrent:null,
        showphotoTemplate:false,

        //派单配置
        dispatchModulecurrent:null,
        showdispatchModule:false,

        //水印配置
        watermarkConfigurationcurrent:null,
        showwatermarkConfiguration:false,
        list:{},

        // 服务商
        service:[],
        // 第一条数据
        first:{},

        //服务商id
        pid:0,

        //派单配置
        dispatch_config:{},

        //客户好评
        value1: false

      }
    },

    mounted() {
      // 获取服务商
      // this.getFuwushang();

      // 获取时效配置
      this.getConfig();
      // 获取水印配置
      this.getConfig2();
      // 获取服务派单配置
      this.getConfig1();
      // 获取好评
      this.getPingjia();
    },

    methods:{
      // 获取服务商
      // getFuwushang(){
      //   let where = {
      //     page :1,
      //     limit: 100
      //   }
      //   getfacilitator(where).then(res => {
      //     console.log(res)
      //     this.service = res.data.list;
      //     this.first = res.data.list[0];
      //     this.$set(this.where,'id',this.first.id);
      //     // this.where.id = this.first.id;
      //
      //     // 默认服务商id
      //     this.pid = this.first.id;
      //
      //     this.getConfig(this.first.id);
      //   })
      // },

      //获取时效配置
      getConfig(id){
        getservice_configuration(this.id).then(res => {
          console.log(res);
          this.current = res.data
          // this.current = {
          //   platform_join_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   service_response_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   technician_join_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   platform_dispatch_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   technician_depart_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   technician_arrive_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   technician_accomplish_remind:{
          //     normal:'',
          //     assess:'',
          //   },
          //   technician_return_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   place_technician_join_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   place_technician_depart_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   place_technician_arrive_remind:{
          //     normal:'',
          //     assess:''
          //   },
          //   place_technician_accomplish_remind:{
          //     normal:'',
          //     assess:''
          //   },
          // };
        })
      },

      // 选择服务商
      selectwhere(value){
        console.log(value)
        this.getConfig(value);
        console.log(this.current);
        this.pid = value;
      },

      // 点击时效配置
      Showageing(){
        // this.current = {
        //   platform_join_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   service_response_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   technician_join_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   platform_dispatch_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   technician_depart_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   technician_arrive_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   technician_accomplish_remind:{
        //     normal:'',
        //     assess:'',
        //   },
        //   technician_return_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   place_technician_join_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   place_technician_depart_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   place_technician_arrive_remind:{
        //     normal:'',
        //     assess:''
        //   },
        //   place_technician_accomplish_remind:{
        //     normal:'',
        //     assess:''
        //   },
        // };
        this.showEdit = true;
      },

      //照片模板配置
      photoTemplate(){
        this.showphotoTemplate = true;
      },

      //派单配置
      DispatchModule(){
        this.showdispatchModule = true;
      },
      // 获取派单配置接口
      getConfig1(){
        getget_dispatch_config(this.id).then(res => {
          console.log('获取派单配置接口',res)
          this.dispatchModulecurrent = res.data;
        })
      },


      //水印配置
      WatermarkConfiguration(){
        this.showwatermarkConfiguration = true;
      },
      // 获取水印配置
      getConfig2(){
        get_watermark_info(this.id).then(res => {
          this.list = res.data;
        })
      },


      // 获取好评
      getPingjia(){
        get_owners_favorable_comment_info(this.id).then(res => {
          console.log('获取好评',res)
          //0未开启  1开启
          if(res.data.owner_praise_switch == 0){
            this.value1 = false;
          }else {
            this.value1 = true;
          }
        })
      },
      //客户开票开关配置
      changehaoping(value){
        console.log(value)
        let owner_praise_switch = value ? 1 : 0;
        let data = {
          channel_id: this.id,
          owner_praise_switch: owner_praise_switch
        }
        edit_owners_favorable_comment(data).then(res => {
          console.log('客户开票开关配置',res)
          if(res.code == 200){
            this.$message.success(res.msg)
            this.getPingjia();
          }else {
            this.$message.error(res.msg)
          }
        })
      },


    }

  }
</script>

<style lang="scss" scoped>
  .ageing{
    .ageing_b{
      padding-top: 15px;
      padding-bottom: 15px;
      background: #ffffff;
      border: 1px solid #e9eaf1;
      border-radius: 6px;
      margin-top: 15px;
    }

    .ageing_b_text_l{
      display: inline-block;
      width: 160px;
      font-size: 16px;
      font-weight: 400;
      color: #868792;
      text-align: right;
    }
    .ageing_b_text_r{
      margin-left: 15px;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #050505;
    }
  }

  // 左右并行
  .parallel:after{
    content: '';
    clear: both;
    display: block;
  }
  .parallel{
    .left{
      width: 30%;
      float: left;
      font-size: 18px;
      font-weight: 700;
      color: #050505;
      margin-top: 5px;
    }
    .right{
      width: 50%;
      float: left;
      margin-left: 15px;
    }
  }

  .right_img{
    width: 300px;
    height: 280px;
    padding: 10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: auto;
  }


</style>
